import '../login_status/login_status'

import auth0 from 'auth0-js'

import { auth0Login } from '../../auth0/sso'
import { readCookie, setCookie } from '../../utils/cookie_manager'

const currentUser = $.cookie('logged_messages') && $.cookie('logged_messages').includes('true')
//Verificamos si viene de APP para no aplicar el checkSession
const is_app = readCookie('in_app') === 'true'
//let isAppWebview = document.querySelector("#is_app_webview")?.value==='true'

const clientAuth0 = new auth0.WebAuth({
  domain: AUTH0_CONFIG.domain,
  clientID: AUTH0_CONFIG.clientId,
  redirectUri: AUTH0_CONFIG.redirectUri,
  audience: AUTH0_CONFIG.audience,
  scope: 'read:current_user',
  responseType: 'token id_token',
})

function setAriaLabel(favBtn, isFavourite) {
  if (favBtn) {
    const favouriteData = JSON.parse(favBtn.getAttribute('data-favourite-info'))
    favBtn.setAttribute(
      'aria-label',
      `${isFavourite ? favouriteData.favourite : favouriteData.unfavourite} - ${favouriteData.title}`
    )
  }
}
export function favouriteHotels() {
  let favouriteHotelCodes = readCookie('favourite_hotel_codes')
  if (favouriteHotelCodes !== null) {
    favouriteHotelCodes = decodeURIComponent(favouriteHotelCodes).split(',')
  }

  return favouriteHotelCodes
}
window.FavouriteHotels = {
  markFavouriteHotels() {
    let favouriteHotelCodes = readCookie('favourite_hotel_codes')

    if (favouriteHotelCodes !== null) {
      favouriteHotelCodes = unescape(favouriteHotelCodes).split(',')
      let dataFilterable
      favouriteHotelCodes.forEach(function (hotelCode) {
        $(`.favourite-btn.hotel-code-${hotelCode}:not(.static-active)`).addClass('static-active')
        if ($(`.favourite-btn.hotel-code-${hotelCode}`).parents('.favourite-hotel-block').length)
          $(`.favourite-btn.hotel-code-${hotelCode}`).parents('.favourite-hotel-block').addClass('active')
        $(`*[data-code="${hotelCode}"]`).attr('data-favourite', 1)
        $(`*[data-code="${hotelCode}"]`).each(function (index, el) {
          const $el = $(el)
          dataFilterable = $el.attr('data-filterable')
          if (typeof dataFilterable !== 'undefined') {
            $el.attr('data-filterable', dataFilterable.replace('favourite":[0]', 'favourite":[1]'))
          }
        })

        const favBtn = document.querySelector(`.favourite-btn.hotel-code-${hotelCode}`)
        setAriaLabel(favBtn, true)
      })
    }
  },
  favouriteButtonClick($el) {
    if ($el.hasClass('disabled')) return false
    const addEvent = new Event('favourite_hotels:added')
    const removeEvent = new Event('favourite_hotels:removed')

    // If some hotel is beign disliked, remove it when we click in another
    if ($el.hasClass('undo-active')) {
      $('.favourite-btn.dislike').closest('.card').remove()
    }

    const endpoint = $el.data('endpoint')

    if ($el.hasClass('active') || $el.hasClass('static-active')) {
      removeEvent.$origin_element = $el
      document.dispatchEvent(removeEvent)

      FavouriteHotels.deleteFromFavourites($el, endpoint)
    } else {
      addEvent.$origin_element = $el
      document.dispatchEvent(addEvent)

      FavouriteHotels.addToFavourites($el, endpoint)
    }
  },
  addToFavourites($el, endpoint) {
    const mobile_view = location.search.indexOf('mobile=true') > -1
    const params = mobile_view ? '?mobile=true' : ''
    const favBtn = $(`[data-hotelcode='${$el.data('hotelcode')}']`).get(0)
    const dataHotelCode = $el.data('hotelcode')

    // Temporary solution until the issue between iOs and Auth0 is resolved.
    if (currentUser && !is_app) {
      clientAuth0.checkSession(
        {
          audience: AUTH0_CONFIG.audience,
          scope: 'read:current_user',
        },
        function (err, authResult) {
          if (err) {
            const logout_url = `${window.location.protocol}//${window.location.host}/auth/logout`
            window.location.href = logout_url
          }
        }
      )

      $.ajax({
        url: `${endpoint}?current_path=${window.location.pathname}${params}`,
        method: 'PUT',
        dataType: 'script',
        statusCode: {
          200() {
            $(`[data-hotelcode='${$el.data('hotelcode')}']`)
              .addClass('active')
              .removeClass('dislike')
            if ($el.parents('.favourite-hotel-block').length) $el.parents('.favourite-hotel-block').addClass('active')
            if ($el.parents('.gmapz-ibx-content').length || $el.parents('.info-box-mobile').length) {
              IB.hotels_filter_map.changeMarkerIcon($el.data('id'), 'hotel_one_fav')
            }
            if (!$el.hasClass('private-area')) {
              $el.closest('.card').attr('data-favourite', 1)
              const filterable = $el.closest('.card').data('filterable')
              if (filterable !== undefined && filterable.length) filterable[0].favourite = [1]
              $el.closest('.card').attr('data-filterable', filterable)
            }

            setAriaLabel(favBtn, true)
            FavouriteHotels.updateMyIbSidebarCounter()
          },
        },
      })
    } else {
      FavouriteHotels.updatePendingFavourite(dataHotelCode)
      auth0Login(false)
    }
  },
  deleteFromFavourites($el, endpoint) {
    const $cnt = $el.closest('.card')
    const $alert = $('.alert-delete-favourite')
    const name = $cnt.find('.title-link').text()
    const favBtn = $(`[data-hotelcode='${$el.data('hotelcode')}']`).get(0)

    if ($el.hasClass('private-area')) {
      $(`[data-hotelcode='${$el.data('hotelcode')}']`)
        .removeClass('active static-active')
        .addClass('dislike')

      if (!$alert.parent().is('body')) $('body').append($alert)

      $('.favourite-btn').addClass('undo-active')

      $alert.addClass('active').find('.name').text(name)

      FavouriteHotels.favouriteDeleteTimeout = setTimeout(function () {
        $.ajax({
          url: endpoint,
          method: 'DELETE',
          dataType: 'script',
        }).done(function () {
          $alert.removeClass('active')

          $el.closest('.card').remove()
          $('.favourite-btn').removeClass('undo-active')
          let codes = readCookie('favourite_hotel_codes')

          $('a.favourite-hotels').each(function (index, element) {
            const $this = $(element)
            let t = $this.text()

            if (codes !== null && codes !== '') {
              codes = unescape(codes).split(',')
              const l = codes.length

              t = t.replace(`(${l + 1})`, `(${l})`)
              $this.text(t)
            } else {
              t = t.replace('(1)', '')
              $this.text(t)

              if ($('.favourite-empty-cnt').length) {
                $('.favourite-list-cnt').addClass('hidden')
                $('.favourite-empty-cnt').removeClass('hidden')
              }
            }
          })

          setAriaLabel(favBtn, false)

          FavouriteHotels.updateMyIbSidebarCounter()
        })
      }, 5000)
    } else {
      $.ajax({
        url: `${endpoint}?current_path=${window.location.pathname}`,
        method: 'DELETE',
        dataType: 'script',
        statusCode: {
          200() {
            $(`[data-hotelcode='${$el.data('hotelcode')}']`)
              .addClass('dislike')
              .removeClass('active static-active')
            if ($el.parents('.gmapz-ibx-content').length || $el.parents('.info-box-mobile').length) {
              IB.hotels_filter_map.changeMarkerIcon($el.data('id'), 'hotel_one')
            }
            if ($el.parents('.favourite-hotel-block').length) {
              $el.parents('.favourite-hotel-block').removeClass('active')
            }
            $el.closest('.card').attr('data-favourite', 0)
            const filterable = $el.closest('.card').data('filterable')
            if (filterable !== undefined && filterable.length) filterable[0].favourite = [0]
            $el.closest('.card').attr('data-filterable', filterable)
            setAriaLabel(favBtn, false)
          },
        },
      })
    }
  },
  stopDeleteFavourite() {
    $('.hotel-list').find('.favourite-btn.dislike').removeClass('dislike').addClass('static-active')
    $('.alert-delete-favourite').removeClass('active')
    $('.favourite-btn').removeClass('disabled')
    $('.favourite-btn').removeClass('undo-active')
    clearTimeout(FavouriteHotels.favouriteDeleteTimeout)
  },
  updatePendingFavourite(dataHotelCode) {
    const pendingFavouriteHotelCode = readCookie('pending_favourite_hotel_code')
    if (pendingFavouriteHotelCode !== null) {
      if (LoginStatus.check()) {
        const $pendingFavBtn = $(`.favourite-btn.hotel-code-${pendingFavouriteHotelCode}:not(.static-active)`)
        if ($pendingFavBtn.length) {
          FavouriteHotels.favouriteButtonClick($pendingFavBtn)
          setCookie('pending_favourite_hotel_code', false, '-1')
        }
      } else if ($('#new_iberostar_api_user_session').length === 0) {
        // setCookie('pending_favourite_hotel_code', false, "-1");
        setCookie('pending_favourite_hotel_path', false, '-1')
        setCookie('pending_favourite_hotel_code', dataHotelCode, '1')
      }
    }
  },
  ifElementOnScreen($el) {
    const $window = $(window)
    const docViewTop = $window.scrollTop()
    const docViewBottom = docViewTop + $window.height()
    const elemTop = $el.offset().top
    const elemBottom = elemTop + $el.height()

    if (!(elemBottom <= docViewBottom && elemTop >= docViewTop)) {
      $('html,body').animate(
        {
          scrollTop: elemTop - 100,
        },
        1500
      )
    }
  },
  hideFilterAndOrder() {
    if (IB.currentPageType.isHorizons()) {
      $('.js-footer-fixed-buttons').remove()
    }

    if (!LoginStatus.check()) {
      $('li.favourite-hotels-order').remove()
      $('div.filters-block.favourites').addClass('hidden')
    }
  },
  updateMyIbSidebarCounter() {
    const $menu = $('.my-iberostar-menu, .js-user-menu-remote')

    if ($menu.length) {
      let favouriteHotelCodes = readCookie('favourite_hotel_codes')

      if (favouriteHotelCodes !== null && favouriteHotelCodes !== '') {
        favouriteHotelCodes = unescape(favouriteHotelCodes).split(',')
        $menu.each(function () {
          const $this = $(this)
          const favouriteHotelsMenuItem = $this.find('li.favourite-hotels a')
          if (favouriteHotelCodes.length) {
            const newText = favouriteHotelsMenuItem.text().replace(/\(\d+\)/g, `(${favouriteHotelCodes.length})`)
            $this.data('favouriteHotelsCount', favouriteHotelCodes.length)
            favouriteHotelsMenuItem.text(newText)
          }
        })
      }
    }
  },
  init() {
    this.markFavouriteHotels()
    $('.js-share-hotel').addClass('hidden')
    this.hideFilterAndOrder()
    this.updatePendingFavourite()
  },
}

$(document).on('click', '.favourite-btn', function (e) {
  e.preventDefault()
  FavouriteHotels.favouriteButtonClick($(this))
})

$(document).on('click', '.stop-delete-favourite', function (e) {
  e.preventDefault()
  FavouriteHotels.stopDeleteFavourite()
})

FavouriteHotels.init()
