<template>
  <div ref="mapContainer" class="hotels-map"></div>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import { onMounted, ref, watch } from 'vue'

import { getData } from '../../../../../core/iberapi/hotels_data/hotels_data'
import { addMarkers, init as initMap, removeMarkers } from '../../../../../shared/map/map'
import createHotel from '../../../../../shared/map/map.hotels'
import { useHotelsInfoStore } from '../../../blocks/HotelsInfo/store/hotels_info'
import { loadStyles } from '../../../mixins/LoadStyles/LoadStyles'

/**
 * Styles
 */
const COMPONENT_NAME = 'HotelsMap'
loadStyles({ name: COMPONENT_NAME, folder: 'components/HotelsInfo' })

/**
 * Refs
 */
const mapContainer = ref(null)
let map = null

/**
 * Store
 */
const hotelsInfoStore = useHotelsInfoStore()
const { hotelsFiltered: hotels } = storeToRefs(hotelsInfoStore)

/**
 * Methods
 */
function getMapItems() {
  const hotelsData = getData()
  const hotelList = hotelsData.hotels[1]
  const hotelAbrevs = hotels.value.map(hotel => hotel.abrev)
  const hotelsFiltered = hotelList.filter(hotel => hotelAbrevs.includes(hotel.data.abrev))
  const items = []

  hotelsFiltered.forEach(function (hotel) {
    const item = createHotel(hotel)
    items.push(item)
  })

  return items
}

/**
 * Subscribe to the store to watch for hotels changes in order to update the map
 */
watch(hotels, () => {
  const items = getMapItems()
  if (map) {
    removeMarkers(map)
    addMarkers(items, map)
  }
})

/**
 * Lifecycle
 */
onMounted(async () => {
  const items = getMapItems()
  map = await initMap(mapContainer.value, items)
})
</script>
