// Util methods
const segmentUtils = {
  segment: '',

  /**
   * Sets the segment value.
   * @param {string} newSegment - The new segment to set.
   */
  set(newSegment) {
    this.segment = newSegment
  },

  /**
   * Gets the segment value.
   * @returns {string} The current segment.
   */
  get() {
    return this.segment
  },

  /**
   * Checks if a segment is set.
   * @returns {boolean} True if a segment is set, false otherwise.
   */
  has() {
    return typeof this.segment === 'string' && this.segment !== ''
  },
}

/**
 * Initializes the segment detection and setting process.
 */
const initSegment = () => {
  const elementSelector = 'body'
  const allowedSegments = ['masterbrand', 'city', 'heritage', 'beachfront']

  const block = document.querySelector(elementSelector)

  if (block) {
    const themeClass = block.className.split(' ').find(cls => cls.startsWith('theme-'))

    if (themeClass) {
      const themeSegment = themeClass.split('theme-')[1]

      if (allowedSegments.includes(themeSegment)) {
        const nextSegment = block.className.split(' ').find(cls => cls !== themeClass)
        segmentUtils.set(nextSegment || 'masterbrand')
        return
      }
    }
  }

  segmentUtils.set('masterbrand')
}

export { initSegment, segmentUtils }
