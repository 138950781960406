import { currentDevice } from '../../core/utils/currentDevice'
import { favouriteHotels } from '../../shared/favourite_hotels/favourite_hotels'
import initTemplate from '../../vendors/template_engine/template_engine'

import { MARKERS } from './maps.constants'

export default function createHotel(hotel) {
  const hotel_id = hotel.id
  const hotel_id_num = hotel.id.split('h')[1]

  const template = currentDevice.isMobile
    ? document.querySelector('#mobile-info-box-template').innerHTML
    : document.querySelector('#info-box-template').innerHTML

  const hotel_image = hotel.data.images
  const slide_template = []
  if (hotel_image !== undefined) {
    for (let i = 0; i < hotel_image.length; i++) {
      if (hotel_image[i].crops) {
        slide_template.push(
          `<div class='swiper-slide'>
                <img class='infobox__image infobox-img' alt='${hotel_image[i].alt}' src='${hotel_image[i].crops[0]}' loading="lazy"/>
            </div>`
        )
      }
    }
  }
  const segmentations_html = ''
  let subsegmentations_html = ''

  /**
   * Segmentations: 'city' || 'heritage' || 'beachfront'
   */
  hotel.data.segmentations.forEach(function (value) {
    if (value === null) {
      return subsegmentations_html
    }
    subsegmentations_html += `<li class='badge round uppercase-small ${value.class} not-hover'>${value.title}</li>`
  })

  /**
   * Subgmentations:  'Grand'
   *
   * Do not render Iberostar's badge. Only grand's.
   */
  hotel.data.subsegmentations.forEach(function (value) {
    if (value === null || (value !== null && value.title !== 'Grand')) {
      return subsegmentations_html
    }
    subsegmentations_html += `<li class='badge round uppercase-small ${value.class} not-hover'>${value.title}</li>`
  })

  const json_utag_data_eec = JSON.parse(hotel.data.utag_data_eec)
  json_utag_data_eec.product_impression_list = 'other|other|hotel list'
  const utag_data_eec = JSON.stringify(json_utag_data_eec)
  const template_options = {
    hotel_slides: slide_template.join(''),
    hotel_location: hotel.data.city,
    hotel_url: hotel.data.url,
    hotel_name: hotel.title,
    hotel_category: hotel.data.hotel_category_number,
    hotel_price: IB.currencyForm.buildTagsFromPrice(hotel.data.price),
    hotel_currency: hotel.data.currency,
    hotel_resident_price: hotel.data.resident_price,
    hotel_id,
    hotel_code: hotel.data.hotel_code,
    segmentations: segmentations_html,
    subsegmentations: subsegmentations_html,
    utag_data_eec,
    hotel_id_num,
    grand_style: hotel.data.is_grand === true ? 'grand-collection' : '',
  }

  const infoBoxTemplate = initTemplate(template, template_options)

  let favoriteHotel = new Set(favouriteHotels())
  favoriteHotel = [...favoriteHotel]

  const pin = favoriteHotel.includes(hotel.data.hotel_code) ? MARKERS.hotelOneFav.icon : MARKERS.defaultIcon.icon

  return {
    pin,
    pinSelected: MARKERS.selectedtIcon.icon,
    infoWindow: infoBoxTemplate,
    lat: hotel.data.lat,
    lng: hotel.data.lng,
  }
}
