import '../../shared/info_box/info_box.scss'
import '../../vendors/template_engine/template_engine'

import { Loader } from '@googlemaps/js-api-loader'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import Swiper from 'swiper'
import { Controller, Navigation } from 'swiper/modules'

import loaderSpinner from '../loader_spinner/loader_spinner'

import { GMAPS_API_KEY, GMAPS_ID_MAP, MARKERS } from './maps.constants'

const SELECTED_MARKER_ZOOM = 5
const MAX_ZOOM = 16

let Marker = null

let selectedPins = []

function initSwiper() {
  let infoBoxSwiper

  if (infoBoxSwiper !== undefined) {
    infoBoxSwiper.destroy(true, true)
  }

  Swiper.use([Navigation, Controller])
  infoBoxSwiper = new Swiper('.gm-style-iw-d .swiper-container', {
    navigation: {
      nextEl: '.info-box-next',
      prevEl: '.info-box-prev',
    },
    lazyPreloadPrevNext: 3,
  })
}

function createPin(img, number = false) {
  const pin = document.createElement('div')
  pin.classList.add('cluster-pin')

  const imgElem = document.createElement('img')
  imgElem.classList.add('js-pin-img')
  imgElem.src = img
  pin.appendChild(imgElem)

  if (number) {
    const numberElem = document.createElement('span')
    numberElem.classList.add('cluster-pin__number')
    numberElem.textContent = number
    pin.appendChild(numberElem)
  }

  return pin
}

function selectPin(item, pin) {
  pin.querySelector('.js-pin-img').src = item.pinSelected

  selectedPins.push({ item, pin })
}

function resetPins() {
  selectedPins.forEach(({ item, pin }) => {
    pin.querySelector('.js-pin-img').src = item.pin
  })

  selectedPins = []
}

function fitBounds(map, markers) {
  const bounds = new google.maps.LatLngBounds()
  markers.forEach(marker => {
    bounds.extend(marker.position)
  })
  google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
    this.setZoom(Math.min(this.getZoom(), MAX_ZOOM))
  })
  map.fitBounds(bounds)
}

function resetMap(mapObject) {
  if (mapObject.mapOptions.fitBoundsOnInteractions) {
    fitBounds(mapObject.map, mapObject.markers)
  }
  resetPins()
}

function createClusterRenderer() {
  return {
    render: ({ count, position }, stats, map) => {
      const zIndex = 200 + count

      const clusterOptions = {
        map,
        position,
        zIndex,
        content: createPin(MARKERS.cluster.icon, count),
      }
      return new Marker(clusterOptions)
    },
  }
}

function initMarkers(items, mapObject) {
  const map = mapObject.map
  const markers = []

  items.forEach(item => {
    /*
     * Init infowindow
     */
    const infowindow = new google.maps.InfoWindow({
      content: item.infoWindow,
    })

    /*
     * Init marker
     */
    const pin = createPin(item.pin, item.number === false ? false : 1)
    const marker = new Marker({
      position: new google.maps.LatLng(item.lat, item.lng),
      map,
      content: pin,
      zIndex: item.zIndex || 1,
    })

    infowindow.addListener('closeclick', () => {
      resetMap(mapObject)
    })

    infowindow.addListener('close', () => {
      marker.zIndex = item.zIndex || 1
    })

    marker.addListener('click', function () {
      if (mapObject.currentInfoWindow) {
        mapObject.currentInfoWindow.close()
      }
      resetPins()
      selectPin(item, pin)
      marker.zIndex = 999

      mapObject.currentInfoWindow = infowindow

      if (map.getZoom() < SELECTED_MARKER_ZOOM) {
        map.setZoom(SELECTED_MARKER_ZOOM)
      }

      map.panTo(marker.position)

      const idleListener = mapObject.map.addListener('idle', () => {
        infowindow.open(map, marker)
        google.maps.event.removeListener(idleListener)
      })

      FavouriteHotels.markFavouriteHotels()

      google.maps.event.addListener(infowindow, 'domready', function () {
        if (document.querySelector('.js-has-swiper')) {
          initSwiper()
        }
        item.clickCallback && item.clickCallback()
      })
    })

    markers.push(marker)
  })

  fitBounds(map, markers)
  let markerClusterer = null
  if (mapObject.mapOptions.cluster) {
    markerClusterer = new MarkerClusterer({
      markers,
      map,
      renderer: createClusterRenderer(),
    })
  }

  return {
    markers,
    markerClusterer,
  }
}

export function addMarkers(items, mapObject) {
  const { markers, markerClusterer } = initMarkers(items, mapObject)
  mapObject.markers = markers
  mapObject.markerClusterer = markerClusterer
  return mapObject
}

export function removeMarkers(mapObject) {
  mapObject.markers.forEach(marker => {
    marker.setMap(null)
  })
  mapObject.markerClusterer?.clearMarkers()
  mapObject.markers = []
  mapObject.markerClusterer = null
}

function initMap(Map, mapOptions, mapContainer, items) {
  const map = new Map(mapContainer, mapOptions)

  const mapObject = {
    map,
    mapContainer,
    mapOptions,
    markers: [],
    markerClusterer: null,
    currentInfoWindow: null,
  }
  const { markers, markerClusterer } = initMarkers(items, mapObject)
  mapObject.markers = markers
  mapObject.markerClusterer = markerClusterer

  const idleListener = mapObject.map.addListener('idle', () => {
    loaderSpinner.hide()
    google.maps.event.removeListener(idleListener)
  })

  // Close infowindow on zoom change
  mapObject.map.addListener('zoom_changed', () => {
    if (mapObject.currentInfoWindow) {
      mapObject.currentInfoWindow.close()
    }
  })

  return mapObject
}

export async function initGoogleMapsLibrary() {
  const loader = new Loader({
    apiKey: GMAPS_API_KEY,
    version: 'weekly',
  })

  const { Map } = await loader.importLibrary('maps')
  return { loader, Map }
}

/**
 * This function is for initialize the map
 * @param {*} mapContainer is the container where we want the map
 * @param {*} items is the list we want to show with markers
 * @marker: {
 *   infoWindow: string,
 *   pin: string,
 *   pinSelected: string,
 *   lat: number,
 *   lng: number
 * }
 * @example see example in hotels_map.js
 */
export async function init(mapContainer, items, mapOptions = {}) {
  const disableControls = IB.currentDevice === 'mobile'
  loaderSpinner.show({
    container: mapContainer,
    spinnerSize: 150,
    spinnerColor: 'grey',
  })

  const { loader, Map } = await initGoogleMapsLibrary()
  const { AdvancedMarkerElement } = await loader.importLibrary('marker')

  // Save AdvancedMarkerElement class for further use
  Marker = AdvancedMarkerElement
  let defaultZoom = 3
  const itemWithZoom = items.find(item => typeof item.zoom !== 'undefined')
  if (itemWithZoom) {
    defaultZoom = itemWithZoom.zoom
  }

  let defaultCenter = { lat: 20.5326, lng: -27.9929 }
  const itemWithCenter = items.find(item => typeof item.center !== 'undefined')
  if (itemWithCenter) {
    defaultCenter = itemWithCenter.center
  }

  const defaultMapOptions = {
    mapId: GMAPS_ID_MAP,
    zoomControl: !disableControls,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BUTTON,
    },
    streetViewControl: false,
    fullscreenControl: !disableControls,
    fullscreenControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP,
    },
    scrollwheel: false,
    disableDoubleClickZoom: false,
    center: defaultCenter,
    zoom: defaultZoom,
    mapTypeControl: false,
    /*
     * Custom config
     */
    cluster: true, // Generate marker clusters or not
    fitBoundsOnInteractions: true, // Change map bounds or center map when interacting with markers and infowindows
    ...mapOptions,
  }

  return initMap(Map, defaultMapOptions, mapContainer, items)
}
