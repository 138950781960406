

function checkStatus() {
  let logged_in = false;
  let agencyUserName = $.cookie('agency_user_web_name');

  if (typeof agencyUserName !== 'undefined' && agencyUserName.length > 0) {
    logged_in = true;
  } else {
    let userName = $.cookie('user_web_name');
    if (typeof userName !== 'undefined' && userName.length > 0) {
      logged_in = true;
    }
  }

  if (logged_in) document.body.classList.add('user-logged');

  return logged_in;
}


window.LoginStatus = {
  check: checkStatus
};
